/* html {
  overflow: hidden;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
} */
.ccccn .user-stats {
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  transition: opacity 250ms ease-out;
  left: 0;
  display: flex;
  align-items: flex-end;
  z-index: 1;
}

.ccccn .video-tiles:hover .user-stats {
  opacity: 1;
  background-color: rgb(0, 0, 0, 0.7);
}

.ccccn [data-id] {
  text-transform: capitalize;
}

.ccccn *::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);

  border-radius: 10px;
  background-color: #2d3748;
}

.ccccn *::-webkit-scrollbar {
  width: 12px;
  background-color: #2d3748;
}

.ccccn *::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #1a202c;
}

@media (max-width: 991px) {
  .ccccn .hidden-md-down {
    display: none !important;
  }
}

:root {
  --ccccn-bottom-button-container-height: 80px;
  --ccccn-padding-around-video: 8px;
}

@import url(https://fonts.googleapis.com/css?family=Inter);
* {
  font-family: Inter;
}

.ccccn .cc-snackbar-root-override {
  position: absolute !important;
}
